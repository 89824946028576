import React from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

import {
	CoursePagePriceChoiceSubscribe,
	CoursePagePriceChoiceBuy,
	CoursePagePriceChoiceOnlyBuy,
} from "../../../";

const CoursePagePriceChoice: React.FC = () => {
	const { courseByUrl } = useTypedSelector(({ courses }) => courses);

	return (
		<div
			className={`course-page-price-products ${courseByUrl.url === "knitting-sweater-Evgeny-Antimonov" ? "jcc" : ""}`}
		>
			{courseByUrl.url === "knitting-sweater-Evgeny-Antimonov" ? (
				<CoursePagePriceChoiceOnlyBuy {...courseByUrl} />
			) : (
				<>
					<CoursePagePriceChoiceSubscribe {...courseByUrl} />

					<CoursePagePriceChoiceBuy {...courseByUrl} />
				</>
			)}
		</div>
	);
};

export default CoursePagePriceChoice;
