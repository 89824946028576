import React from "react";

import {useTypedSelector} from "../../../../../hooks/useTypedSelector";

const CabinetSubscribeBlockCurrentPayments: React.FC = () => {
    const {
        userInfo: {payment},
    } = useTypedSelector(({user}) => user);

    return (
        <div className="cabinet-section-content-subscribe-current-payments">
            <p className="cabinet-section-content-subscribe-current-payments__subtitle">
                Способ оплаты
            </p>

            <div className="cabinet-section-content-subscribe-current-payments-wallet">
                <div className="cabinet-section-content-subscribe-current-payments-wallet-item active">
                    <svg
                        width="32"
                        height="21"
                        viewBox="0 0 32 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.17922 1.26116C9.6166 1.26116 12.0343 1.24041 14.4578 1.26116C15.487 1.26997 16.5152 1.35855 17.5429 1.33379C18.4182 1.31271 19.324 1.1187 20.2021 1.0473C21.4814 0.943278 22.7354 1.04327 24.0192 1.04327C25.5991 1.04327 27.1964 1.25315 28.7668 1.33379C29.0673 1.34923 29.2988 1.42801 29.5112 1.62432C29.7134 1.81127 29.9645 2.00205 30.1398 2.17308C30.5448 2.56817 30.8511 3.11888 30.8511 3.69834C30.8511 4.12599 31 4.53485 31 4.98149C31 5.42526 30.9916 5.79297 30.8677 6.21622C30.7488 6.62232 30.7767 7.11778 30.7767 7.53972C30.7767 8.08933 30.7022 8.62276 30.7022 9.16988C30.7022 10.7544 30.7022 12.3475 30.7022 13.899C30.7022 15.2405 30.6802 16.5798 30.6278 17.9098C30.6157 18.218 30.3739 18.5349 30.2721 18.8217C30.176 19.0927 30.0559 19.2507 29.8462 19.4552C29.4097 19.8812 28.2524 19.9273 27.6833 19.9273C25.9768 19.9273 24.302 20 22.5841 20C22.1121 20 21.674 19.8337 21.2112 19.7862C20.4574 19.7087 19.7362 19.6369 18.9739 19.6369C17.8394 19.6369 16.7299 19.4916 15.591 19.4916C14.1022 19.4916 12.6508 19.5054 11.1824 19.6933C9.89483 19.8581 8.62009 19.8547 7.32396 19.8547C6.26777 19.8547 5.2796 19.4916 4.21817 19.4916C3.46499 19.4916 2.6916 19.3427 2.00151 19.0397C1.73069 18.9207 1.6115 18.5176 1.59623 18.2569C1.56221 17.676 1.29846 17.0918 1.29846 16.5137C1.29846 15.3028 1.07514 14.1163 1.07514 12.8983C1.07514 11.8682 1.00071 10.8311 1.00071 9.79126C1.00071 8.31544 0.975629 6.77863 1.22403 5.32447C1.4406 4.05655 1.69 2.19202 3.05194 1.588C3.74996 1.27843 4.70994 1.40642 5.4671 1.40642C5.85895 1.40642 6.55657 1.39986 6.88146 1.18853"
                            stroke="#DD9E5E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.02026 16.0777C5.04908 15.8528 6.26901 15.9325 6.47598 15.9325C6.75996 15.9325 7.04393 15.9325 7.3279 15.9325C7.6326 15.9325 7.92551 16.1394 8.23772 16.1503C8.57608 16.1621 9.30381 16.2737 9.59832 16.114C9.97367 15.9106 10.4463 16.0454 10.8266 15.8599"
                            stroke="#DD9E5E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M22.5139 12.7367C22.4865 13.0043 22.232 13.1843 22.2162 13.4629C22.1947 13.8399 22.2828 14.1189 22.365 14.4798C22.452 14.8618 22.5139 15.1754 22.5139 15.5693C22.5139 15.8765 22.5036 16.0803 22.7041 16.3319C23.0832 16.8073 23.4222 16.7546 24.0192 16.8605C24.3078 16.9117 24.7482 16.9084 25.0077 16.7677C25.2402 16.6417 25.6169 16.7497 25.8472 16.57C25.9369 16.4999 26.0555 16.4855 26.1615 16.4247C26.2895 16.3512 26.2932 16.1542 26.3476 16.0373C26.6269 15.4377 26.757 14.6446 26.757 13.9875C26.757 13.528 26.804 12.8148 26.4427 12.4623C26.2968 12.3199 25.5296 12.4461 25.3013 12.4461C24.918 12.4461 24.5348 12.4461 24.1516 12.4461C23.7302 12.4461 22.8401 12.1058 22.8117 12.8254C22.7855 13.4907 23.0012 14.0013 23.1839 14.6251C23.296 15.008 23.5799 15.3193 23.6305 15.7145C23.6648 15.9824 24.6814 15.9324 24.8919 15.9324C25.16 15.9324 25.6084 15.6327 25.7892 15.4563C26.0183 15.2329 26.087 14.8534 26.087 14.5484C26.087 14.3249 26.015 13.7017 25.8637 13.5356C25.5525 13.194 25.4345 13.1725 24.9373 13.1725C24.7323 13.1725 24.2279 13.0975 24.0772 13.2814C23.9352 13.4545 23.5626 13.3558 23.4651 13.5356C23.0953 14.2171 24.2808 14.7703 24.8215 14.7703C25.1958 14.7703 25.1938 14.3518 25.1938 14.0803C25.1938 13.7075 24.5397 13.9691 24.4328 14.1893C24.251 14.5638 24.3696 14.8734 24.7057 15.0609C24.9532 15.1988 24.7471 15.6981 24.7471 15.9324"
                            stroke="#DD9E5E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M1.22412 6.92638C2.4891 6.92638 3.75442 6.74379 5.02056 6.70849C6.39103 6.67029 7.81251 6.56323 9.18923 6.56323C9.94823 6.56323 10.7091 6.61563 11.4555 6.63586C12.2417 6.65716 13.0196 6.83306 13.8044 6.85376C14.4676 6.87124 15.1205 6.92638 15.7813 6.92638C16.4172 6.92638 17.0508 7.07164 17.6754 7.07164C18.7079 7.07164 19.7404 7.07164 20.7729 7.07164C21.1529 7.07164 21.5028 7.03783 21.8771 7.00305C22.1893 6.97403 22.5062 6.86159 22.8117 6.85376C23.4806 6.83658 24.1207 6.86593 24.7802 6.76498C25.8718 6.59792 27.0257 6.56323 28.1342 6.56323C28.8695 6.56323 29.5965 6.41797 30.3301 6.41797"
                            stroke="#DD9E5E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>

                    {payment.title !== "" ? (
                        <p className="cabinet-section-content-subscribe-current-payments-wallet-item__title">
                            {payment.title}
                        </p>
                    ) : null}
                </div>
                {/* 
                <div className="cabinet-section-content-subscribe-current-payments-wallet-plus">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            y="9"
                            width="20"
                            height="1.5"
                            rx="0.75"
                            fill="black"
                        />
                        <rect
                            x="9"
                            y="20"
                            width="20"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(-90 9 20)"
                            fill="black"
                        />
                    </svg>
                </div> */}
            </div>
        </div>
    );
};

export default CabinetSubscribeBlockCurrentPayments;
