import React from 'react'
import { useDispatch } from 'react-redux'

import { useTypedSelector } from '../../../hooks/useTypedSelector'

import { fetchPaymentCourseById } from '../../../redux/actions/payment/paymentCourse'
import { fetchCourseById } from '../../../redux/actions/courses'

import { Loader } from '../../'

interface OrderPaymentCourseProps {
	paymentNumber: string
}

const OrderPaymentCourse: React.FC<OrderPaymentCourseProps> = ({ paymentNumber }) => {
	const dispatch = useDispatch()

	const { payment, isLoaded } = useTypedSelector(({ paymentCourse }) => paymentCourse)
	const { courseById, isLoadedCourseById } = useTypedSelector(({ courses }) => courses)

	React.useEffect(() => {
		dispatch(fetchPaymentCourseById(paymentNumber) as any)
	}, [])

	React.useEffect(() => {
		if (isLoaded && !isLoadedCourseById) {
			dispatch(fetchCourseById(payment.courseId) as any);
		}
	}, [isLoaded])


	React.useEffect(() => {
		if (isLoaded && payment.status === "pending" && isLoadedCourseById) {
			if (payment.confirmation) {
				const checkout = new window.YooMoneyCheckoutWidget({
					confirmation_token: payment.confirmation.confirmation_token,
					return_url: `${process.env.REACT_APP_DOMEN}/payment/status/${payment.number}`,

					customization: {
						colors: {
							controlPrimary: "#DD9E5E",
							background: "#F9F9F9",
						},
					},

					error_callback: (error: any) => {
						console.log(error);
					},
				});

				checkout.on('success', () => {
					try {
						var _tmr = window._tmr || (window._tmr = []);

						_tmr.push({
							type: "reachGoal",
							id: 3311444,
							goal: courseById.adsVk.buy,
						});

						var ym = window.ym || (window.ym = []);

						ym(84942475, 'reachGoal', `${courseById.title} (Покупка)`)
					} catch (e) {
						console.log(e);
					}
				});

				checkout.render("order-payment-widget");
			} else {
				window.location.href = "/";
			}
		}
	}, [isLoaded, isLoadedCourseById])

	return (
		<div className="order-payment">
			{isLoaded ? (
				<div
					className="order-payment-widget"
					id="order-payment-widget"
				></div>
			) : (
				<Loader />
			)}
		</div>
	)
}

export default OrderPaymentCourse