import React from 'react'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from "redux-form";

import { RenderTextarea, RenderRadio } from "../../";

import { useTypedSelector } from '../../../hooks/useTypedSelector'

const CabinetSubscribeDisableFeedbackForm: React.FC<{} & InjectedFormProps<{}, {}>> = ({ handleSubmit, invalid, pristine, submitting, form }) => {
	const selector = formValueSelector("cabinet-subscribe-disable-feedback-form");

	// const { feedbackValue } =
	// useTypedSelector((state) => {
	// 	const { feedback } = selector(
	// 		state,
	// 		"feedback"
	// 	);
	// 	return {
	// 		feedbackValue: feedback,
	// 	};
	// });

	return (
		<form className='cabinet-subscribe-disable-block-content-text-form' onSubmit={handleSubmit}>
			<div className="cabinet-subscribe-disable-block-content-text-form-checkbox">
				<Field component={RenderRadio} name="feedback" type='radio' value={`Дорогая цена подписки`} />
			</div>
			<div className="cabinet-subscribe-disable-block-content-text-form-checkbox">
				<Field component={RenderRadio} name="feedback" type='radio' value={`Не понравилось качество курсов`} />
			</div>
			<div className="cabinet-subscribe-disable-block-content-text-form-checkbox">
				<Field component={RenderRadio} name="feedback" type='radio' value={`Мне нужен был только один курс`} />
			</div>
			<div className="cabinet-subscribe-disable-block-content-text-form-checkbox">
				<Field component={RenderRadio} name="feedback" type='radio' value={`Мало курсов по тематике которая мне интересна`} />
			</div>
			{/* <div className="cabinet-subscribe-disable-block-content-text-form-checkbox">
				<Field component={RenderRadio} name="feedback" type='radio' value={`Другая причина`} />
			</div> */}

			{/* {feedbackValue === "Другая причина" ? (
				<div className="cabinet-subscribe-disable-block-content-text-form-textarea">
					<Field component={RenderTextarea} name="feedback__comment" type='text' label={"Опишите причину"} />
				</div>
			) : (
				null
			)} */}

			<button className={`btn ${invalid || pristine || submitting ? "disabled" : ""} cabinet-subscribe-disable-block-content-text-form__btn`} disabled={invalid || pristine || submitting}>
				Отменить подписку
			</button>
		</form>
	)
}


export default reduxForm<{}, {}>({
	form: "cabinet-subscribe-disable-feedback-form",
	// validate,
})(CabinetSubscribeDisableFeedbackForm);