import React from "react";
import {Helmet} from "react-helmet";

import {PolicyBlock} from "../components/";

const PublicOffer: React.FC = () => {
    const documentContent = [
        {
            title: "",
            content: [
                {
                    type: "text",
                    text: "Настоящий документ, постоянно размещенный в сети Интернет по сетевому адресу:",
                },
                {
                    type: "text",
                    text: "[https://masters.hobjob.ru/public-offer](https://masters.hobjob.ru/public-offer), является публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации на заключение договора (далее - Договор) с любым заинтересованным физическим лицом (далее - Заказчик). Надлежащим акцептом настоящей оферты в соответствии со статьей 438 Гражданского кодекса Российской Федерации является совершение Заказчиком в совокупности следующих действий:",
                },
                {
                    type: "list",
                    items: [
                        "- указание адреса электронной почты и/или имени при оформлении заказа в специальной форме на сайте, нажатие кнопки «Оформить заказ» при покупке Курса на Сайте;",
                        "- оплата Заказчиком стоимости Курса, указанной Исполнителем в момент покупки.",
                    ],
                },
                {
                    type: "text",
                    text: "Оплачивая стоимость Курса, Заказчик:",
                },
                {
                    type: "list",
                    items: [
                        "- гарантирует достоверность и актуальность сведений, предоставляемых о себе;",
                        "- гарантирует, что он является совершеннолетним и полностью дееспособным лицом;",
                        "- соглашается, что он самостоятельно несет ответственность за любые последствия, возникающие в результате указания недостоверных, неактуальных или неполных сведений о себе.",
                    ],
                },
                {
                    type: "text",
                    text: "Датой акцепта оферты Заказчиком (датой заключения Договора) считается дата зачисления денежных средств за оплату Исполнителем Курса на расчетный счет Исполнителя.",
                },
                {
                    type: "text",
                    text: "Исполнителем по Договору является:",
                },
                {
                    type: "list",
                    items: [
                        "Индивидуальный предприниматель Нагибин Никита Сергеевич",
                        "ОГРНИП 321619600076431",
                        "ИНН 614320144347",
                        "Рс 40802810600001997997",
                        "БИК 044525974",
                        "АО “ТИНЬКОФФ БАНК”",
                        "Кор. счет 30101810145250000974",
                        "Адрес: 341381, г. Волгодонск, Морская ул., 122, кв. 38.",
                    ],
                },
            ],
        },
        {
            title: "1. Термины и определения",
            content: [
                {
                    type: "text",
                    text: `В Договоре, если иное прямо не вытекает из текста, указанные ниже термины будут иметь следующие значения:`,
                },
                {
                    type: "text",
                    text: `Сайт - Составное произведение, представляющее собой совокупность информации, текстов, графических элементов, дизайна, изображений, фото - и видеоматериалов, иных результатов интеллектуальной деятельности, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет под доменными именами: [https://hobjob.ru/](https://hobjob.ru/), [https://masters.hobjob.ru/](https://masters.hobjob.ru/), включая Мобильные приложения.`,
                },
                {
                    type: "text",
                    text: `Личный кабинет - Совокупность защищенных страниц Сайта, созданных в результате регистрации Заказчика и доступных при вводе его аутентификационных данных (адреса электронной почты и пароля) в предусмотренные для этого поля на Сайте. Юридически значимые действия, совершенные Заказчиком через его Личный Кабинет являются совершенными с его простой электронной подписью, где идентификатором и ключом электронной подписи является его аутентификационных данные.`,
                },
                {
                    type: "text",
                    text: `Курс - Определенная часть информации под названиями (перечень наименований не ограничен и может быть изменен Исполнителем): «Курс», «Практический курс», «Профессиональный курс», «Видеокурс», «Экспресс-курс», «Видео лекция», содержащийся на Сайте, доступ к которой передается путем предоставления Заказчику определенных данных и команд, состоящая из совокупности взаимосвязанных лекций и материалов (тексты, фото- и видеоматериалы, иные объекты интеллектуальных прав), объединенных единой темой, расположенных в определенной последовательности и направленных на самостоятельное приобретение Заказчиком знаний и навыков по соответствующей теме, а также очные мероприятия (лекции, образовательные курсы и т. п.), проводимые Исполнителем.`,
                },
                {
                    type: "text",
                    text: `Контент - Объекты авторского права, размещенные на Сайте.`,
                },
            ],
        },
        {
            title: "2. Предмет Договора",
            content: [
                {
                    type: "text",
                    text: `2.1. Исполнитель оказывает Заказчику услуги дополнительного образования, в соответствии с одним из видом деятельности 85.41 “Образование дополнительное детей и взрослых”, (ОГРНИП 321619600076431 от 14.04.2021) и обязуется предоставить Заказчику через информационно-коммуникационную сеть «Интернет» доступ к Курсу, а Заказчик обязуется уплатить Исполнителю вознаграждение за вышеуказанные услуги и предоставленный доступ.`,
                },
                {
                    type: "text",
                    text: `Исполнитель предоставляет Заказчику доступ только к той части Сайта (данным и командам, необходимым для доступа к Курсу), которая соответствует Курсу, выбранному Заказчиком.`,
                },
                {
                    type: "text",
                    text: `2.2. По дополнительному согласованию с Исполнителем, доступ может быть предоставлен третьему лицу, на которое укажет Заказчик, при условии несения ответственности за его действия, как за свои собственные. В этом случае, в рамках исполнения Договора третье лицо приравнивается к Заказчику и имеет все те же права и обязанности что и Заказчик, если иное прямо не указано в Договоре.`,
                },
                {
                    type: "text",
                    text: `2.3. Исполнитель размещает перечень Курсов, доступных для приобретения, на Сайте. Информация о стоимости, содержании Курса доступна Заказчику на странице выбранного Курса на Сайте. Заказчик оставляет за собой право дополнять Курс новыми материалами. При этом состав Курса, приобретенного Исполнителем до дополнения новыми материалами, остается неизменным.`,
                },
                {
                    type: "text",
                    text: `2.4. Обязательства Исполнителя по Договору считаются исполненными с момента предоставления Заказчику доступа к Курсу.`,
                },
                {
                    type: "text",
                    text: `2.5 Приобретая профессиональный курс по тарифу, подразумевающему аттестацию (получение диплома), Заказчик получает дополнительную возможность сдать экзамен по выбранному курсу. Успешная сдача экзамена Заказчиком подтверждается дипломом установленного государственного образца.`,
                },
                {
                    type: "text",
                    text: `Подробности программы профессионального курса, условий обучения и получения диплома указаны на сайте непосредственно в описании курса.`,
                },
            ],
        },
        {
            title: "3. Правила предоставления доступа к Курсу",
            content: [
                {
                    type: "text",
                    text: `3.1 Под предоставлением доступа к Курсу имеется в виду предоставление доступа к определенной совокупности данных и команд, позволяющих интерактивно взаимодействовать с определенным Контентом.`,
                },
                {
                    type: "text",
                    text: `3.2 Исполнитель предоставляет Заказчику доступ к Курсу после оплаты стоимости Курса в порядке, предусмотренном разделом 5 Договора.`,
                },
                {
                    type: "text",
                    text: `3.3 Исполнитель информирует Заказчика о предоставлении доступа к Курсу путем направления соответствующего уведомления и/или логина и пароля от Личного кабинета на электронную почту Заказчика, указанную им при регистрации на Сайте.`,
                },
            ],
        },
        {
            title: "4. Права и обязанности Сторон",
            content: [
                {
                    type: "text",
                    text: `4.1. Исполнитель обязан:`,
                },
                {
                    type: "list",
                    items: [
                        "4.1.1 Осуществлять информационную поддержку Заказчика ежедневно с 10:30 до 22:30 по московскому времени. При этом Исполнитель оставляет за собой право изменять временной интервал и режим осуществления информационной поддержки. Все вопросы по поддержке направляются Исполнителю по электронному адресу: support@hobjob.ru",
                    ],
                },
                {
                    type: "text",
                    text: `4.2. Исполнитель вправе:`,
                },
                {
                    type: "list",
                    items: [
                        "4.2.1. Без согласования с Заказчиком привлекать третьих лиц для исполнения настоящего Договора, оставаясь ответственным за действия таких лиц, как за свои собственные.",
                        "4.2.2. Запрашивать у Заказчика всю необходимую информацию, документы для надлежащего исполнения обязательств по настоящему Договору.",
                        "4.2.3. Изменять стоимость Курса. Информация об актуальной стоимости Курса доступна Заказчику на сайте на странице конкретного Курса. Изменение стоимости Курса в отношении уже оплаченного Заказчиком доступа к Курсу не производится.",
                        "4.2.4.Приостанавливать работу сайта [https://hobjob.ru/](https://hobjob.ru/) для проведения необходимых плановых профилактических и ремонтных работ на технических ресурсах Исполнителя.",
                        "4.2.5. Изменять содержание Курса, включая темы отдельных лекций, их содержание.",
                    ],
                },
                {
                    type: "text",
                    text: `4.3. Заказчик обязан:`,
                },
                {
                    type: "list",
                    items: [
                        "4.3.1. Своевременно и в полном объеме оплатить стоимость Курса.",
                        "4.3.2. Своевременно предоставлять полную и достоверную информацию, необходимую для продажи Курса (в том числе при регистрации на Сайте).",
                        "4.3.3. Обеспечить конфиденциальность логина и пароля к личному кабинету на Сайте.",
                    ],
                },
            ],
        },
        {
            title: "5. Финансовые условия",
            content: [
                {
                    type: "text",
                    text: `5.1. Стоимость Курса указывается на Сайте.`,
                },
                {
                    type: "text",
                    text: `5.2. Стоимость услуг Исполнителя не облагается НДС на основании пп. 26 п. 2 ст. 149 Налогового Кодекса РФ.`,
                },
                {
                    type: "text",
                    text: `5.3. Заказчик производит оплату в размере 100% (ста процентов) Курса единовременно или в рассрочку* в соответствии с условиями, установленными Исполнителем, в отношении определенного Курса при условии, что такая возможность предусмотрена Исполнителем`,
                },
                {
                    type: "text",
                    text: `5.4. Датой исполнения Заказчиком обязательств по Договору является дата поступления денежных средств на расчетный счет Исполнителя.`,
                },
                {
                    type: "text",
                    text: `5.5. Исполнитель не контролирует аппаратно-программный комплекс электронной системы платежей. Если в результате таких ошибок произошло списание денежных средств Заказчика, но платеж не был авторизован электронной системой платежей, обязанности по возврату денежных средств Заказчику лежат на провайдере электронной системы платежей.`,
                },
                {
                    type: "text",
                    text: `5.6. Фискальные документы отправляются в электронном виде на почту Заказчика, указанную при регистрации на сайте в соответствии с законодательством РФ.`,
                },
            ],
        },

        {
            title: "6. Интеллектуальная собственность",
            content: [
                {
                    type: "text",
                    text: `6.1. Исключительное право на Сайт, в том числе на его части принадлежат Исполнителю, либо им получены все необходимые права и разрешения.`,
                },
                {
                    type: "text",
                    text: `6.2. Заказчик имеет право пользоваться Сайтом в рамках предоставленного функционала и интерактивного взаимодействия с доступной информацией на все время доступа к Сайту в соответствии с настоящим Договором.`,
                },
                {
                    type: "text",
                    text: `6.3. Заказчик обязан:`,
                },
                {
                    type: "list",
                    items: [
                        "- воздерживаться от любых действий, которые нарушают права Исполнителя на результаты интеллектуальной деятельности, в частности, не копировать, не записывать, не воспроизводить, не распространять любые результаты интеллектуальной деятельности Исполнителя без письменного разрешения Исполнителя;",
                        "- не предоставлять свои аутентификационных данные для доступа в Личный кабинет на Сайте третьим лицам. В случае утраты, а также в случаях незаконного получения доступа к логину и паролю третьими лицами, Заказчик обязуется незамедлительно сообщить об этом Исполнителю, путем направления уведомления по адресу: support@hobjob.ru До момента отправки указанного извещения все действия, совершенные с использованием Личного кабинета Заказчика, считаются совершенными Заказчиком.",
                    ],
                },
                {
                    type: "text",
                    text: `6.4. Использование Заказчиком Контента Сайта, его содержимого и составляющих (как в целом, так и фрагментарно) и прочих разработанных Исполнителем технических решений не означает передачи (отчуждения) Заказчику и / или любому третьему лицу прав на результаты интеллектуальной деятельности, как в целом, так и в части.`,
                },
                {
                    type: "text",
                    text: `6.5. К конфиденциальной относится любая информация, опубликованная в открытом доступе и не являющаяся доступной для всеобщего сведения, ставшая известной Заказчику в рамках исполнения Сторонами обязательств по Договору. Заказчик обязуется не разглашать конфиденциальную информацию третьим лицам без предварительного письменного согласия Исполнителя.`,
                },
            ],
        },

        {
            title: "7. Ответственность исполнителя",
            content: [
                {
                    type: "text",
                    text: `7.1. Исполнитель не несёт ответственности за качество соединения с сетью Интернет и функционирование оборудования и программного обеспечения Заказчика.`,
                },
                {
                    type: "text",
                    text: `7.2. При неисполнении Заказчиком обязательства, предусмотренного пунктами 6.3., 6.5. Договора, и выявлении Исполнителем факта доступа третьих лиц к содержанию Курса Заказчик обязан во внесудебном порядке по письменному требованию Исполнителя оплатить штраф в размере 100000 (ста тысяч) рублей за каждый случай несанкционированного предоставления доступа третьим лицам.`,
                },
                {
                    type: "text",
                    text: `7.3. В случае нарушения Заказчиком условий Договора Исполнитель вправе прекратить доступ Заказчика к Личному кабинету, а также заблокировать доступ Заказчика к Курсу (-ам).`,
                },
                {
                    type: "text",
                    text: `7.4. Исполнитель не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему Договору, а также за возможный ущерб, возникший в результате:`,
                },

                {
                    type: "list",
                    items: [
                        "- сбоев в работе Сайта и (или) иного программного обеспечения, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода в программном обеспечении;",
                        "- отсутствия (невозможности установления, прекращения и пр.) Интернет-соединений;",
                        "- установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений, затрудняющих или делающих невозможным исполнение настоящего Договора;",
                        "- других случаев, связанных с действием (бездействием) пользователей Интернета и/или других субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования;",
                        "- использования (невозможность использования) и какие бы то ни было последствия использования (невозможности использования) Заказчиком выбранной им формы оплаты услуг по Договору.",
                    ],
                },
            ],
        },

        {
            title: "8. Срок действия Договора. Порядок расторжения",
            content: [
                {
                    type: "text",
                    text: `8.1. Договор вступает в силу с даты его акцепта Заказчиком.`,
                },
                {
                    type: "text",
                    text: `8.2. Договор может быть в любое время расторгнут по инициативе Исполнителя в одностороннем порядке.`,
                },
                {
                    type: "text",
                    text: `8.3. Договор может быть расторгнут в части доступа к Курсу по инициативе Исполнителя в одностороннем внесудебном порядке в случае отсутствия организационной, технической или юридической возможности предоставлять доступ к Курсу.`,
                },
            ],
        },

        {
            title: "9. Изменение условий Договора",
            content: [
                {
                    type: "text",
                    text: `9.1. Исполнитель вправе в одностороннем порядке изменять условия Договора, и такие изменения вступают в силу в момент опубликования новой версии Договора в сети Интернет по адресу [https://hobjob.ru/public-offer](https://hobjob.ru/public-offer). При этом в части величины Вознаграждения за уже предоставленный доступ старая версия Договора продолжает действовать для Сторон без изменений. В остальной части новые положения Договора имеют обратную силу.`,
                },
                {
                    type: "text",
                    text: `9.2. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами.`,
                },
                {
                    type: "text",
                    text: `9.3. Исполнитель вправе уступать права, переводить долги (в том числе привлекать субагентов и субподрядчиков) по всем обязательствам, возникшим из Договора. Настоящим заказчик дает свое согласие на уступку прав и перевод долга любым третьим лицам. О состоявшейся уступке прав и/или переводе долга Исполнитель информирует Заказчика, размещая соответствующую информацию в Личном кабинете.`,
                },
            ],
        },

        {
            title: "10. Персональные данные",
            content: [
                {
                    type: "text",
                    text: `10.1. Заказчик дает свое согласие Исполнителю на обработку персональных данных Заказчика, указанных им при регистрации на Сайте, а также в Личном кабинете на условиях, предусмотренных Политикой обработки персональных данных, размещенной на сайте [https://hobjob.ru/policy](https://hobjob.ru/policy)`,
                },
                {
                    type: "text",
                    text: `10.2. Заказчик дает согласие на использование Исполнителем отзывов Заказчика об Исполнителе и оказываемых им услугах, оставленных Заказчиком в официальных группах Исполнителя в социальных сетях, в целях размещения таких отзывов на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя. Настоящее согласие действует с даты заключения Договора. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя.`,
                },
                {
                    type: "text",
                    text: `10.3. Заказчик дает свое согласие на получение рекламно-информационного материала от Исполнителя, в том числе в целях возможного получения различных поощрений (бонусы, скидки). Условия получения и размер поощрения определяется Исполнителем самостоятельно. В случае изменения условий поощрения Исполнитель вправе изменять, добавлять и убирать бонусы, направляя письменное уведомление Заказчику на электронную почту, указанному в Личном кабинете. В случае таких изменений Заказчик не вправе требовать от Исполнителя оставлять прежние условия поощрений, не вправе требовать какие-либо выплаты и убытки, связанные с такими изменениями. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления по юридическому адресу Исполнителя.`,
                },
                {
                    type: "text",
                    text: `10.4. Заказчик дает свое согласие Исполнителю на обнародование и дальнейшее использование изображения Заказчика в фото-, видеоматериалах, равно как и зафиксированного в независимых друг от друга кадрах таких видеоматериалов, а также зафиксированного в любых иных объектах изображении в целях размещения такого изображения на официальных сайтах Исполнителя, в информационных и рекламных материалах Исполнителя и любых иных целях, связанных с деятельностью Исполнителя и не противоречащих действующему законодательству. Настоящее согласие действует с даты заключения Договора и распространяется на любые объекты, созданные Исполнителем в период доступа к Платформе Заказчиком, а также полученные от Заказчика в этот период. Настоящее согласие может быть отозвано Заказчиком в любой момент путем направления письменного заявления Исполнителю.`,
                },
            ],
        },

        {
            title: "11. Порядок разрешения споров",
            content: [
                {
                    type: "text",
                    text: `11.1. В случае возникновения любых разногласий между Заказчиком и Исполнителем относительно исполнения каждой из сторон условий Договора, а также любых иных разногласий, такие разногласия должны быть урегулированы с применением обязательного досудебного претензионного порядка. Исполнитель обязуется направить Заказчику претензию в электронном виде на адрес электронной почты, указанный Заказчиком при регистрации на Сайте. Заказчик обязуется направить Исполнителю претензию в электронном виде на адрес электронной почты support@hobjob.ru а также продублировать в претензию в письменном виде на адрес Исполнителя, указанный в разделе 15 Договора. Срок ответа на претензию - 10 (десять) рабочих дней со дня ее получения. Если Законодательством РФ установлен меньший срок, то применяется срок, установленный законодательством. При несоблюдении любой из сторон, всех перечисленных выше условий обязательный претензионный порядок не считается соблюденным.`,
                },
            ],
        },

        {
            title: "12. Уведомления и электронный документооборот",
            content: [
                {
                    type: "text",
                    text: `12.1. Если иное не предусмотрено в Договоре или действующим законодательством, любые уведомления, запросы или иные сообщения (корреспонденция), представляемые Сторонами друг другу, должны быть оформлены в письменном виде и направлены получающей Стороне по почте, путем направления заказной корреспонденции, по электронной почте (на адрес и (или) с адреса Исполнителя – адреса электронной почты в пределах доменной зоны  [https://hobjob.ru/](https://hobjob.ru/) или при помощи курьерской службы. Датой получения корреспонденции считается дата получения уведомления о доставке почтового отправления, в том числе заказной корреспонденции, электронного подтверждения доставки при отправлении электронной почтой (или в отсутствии такового – момент отправления сообщения), или день доставки в случае отправления корреспонденции с курьером. При рассмотрении споров в суде переписка Сторон по электронной почте, а также переписка через Личный кабинет будут признаны Сторонами достаточными доказательствами.`,
                },
                {
                    type: "text",
                    text: `12.2. При исполнении (изменении, дополнении, прекращении) Договора, а также при ведении переписки по указанным вопросам допускается использование аналогов собственноручной подписи Сторон. Стороны подтверждают, что все уведомления, сообщения, соглашения и документы в рамках исполнения Сторонами обязательств, возникших из Договора, подписанные аналогами собственноручной подписи Сторон, имеют юридическую силу и обязательны для исполнения Сторонами. Под аналогами собственноручной подписи понимаются уполномоченные адреса электронной почты и учетные данные к Личному кабинету.`,
                },
                {
                    type: "text",
                    text: `12.3. Стороны признают, что все уведомления, сообщения, соглашения, документы и письма, направленные с использованием уполномоченных адресов электронной почты и Личного кабинета, считаются направленными и подписанными Сторонами, кроме случаев, когда в таких письмах прямо не указано обратное.`,
                },
                {
                    type: "text",
                    text: `12.4. Уполномоченными адресами электронной почты Сторон признаются:`,
                },
                {
                    type: "list",
                    item: [
                        "- Для Исполнителя: support@hobjob.ru или иной в пределах доменной зоны  [https://hobjob.ru/](https://hobjob.ru/). Для Заказчика: адрес электронной почты, указанный при регистрации на Сайте.",
                    ],
                },
                {
                    type: "text",
                    text: `12.5. Стороны обязуются обеспечивать конфиденциальность сведений и информации, необходимых для доступа к уполномоченным адресам электронной почты и Личному кабинету, не допускать разглашение такой информации и передачу третьим лицам. Стороны самостоятельно определяют порядок ограничения доступа к такой информации.`,
                },
            ],
        },

        {
            title: "13. Прочие условия",
            content: [
                {
                    type: "text",
                    text: `13.1. Заказчик вправе передать свои права и обязанности по Договору третьей Стороне при условии сохранения доступа к Курсу только при условии получения письменного согласия Исполнителя и на основании отдельного соглашения, заключенного Сторонами.`,
                },
                {
                    type: "text",
                    text: `13.2. Недействительность одного из условий договора не влечет недействительности всего Договора в целом. В случае признания условия Договора недействительным, стороны обязаны вступить в переговоры и изменить договор таким образом, чтобы он продолжил свое действие.`,
                },
                {
                    type: "text",
                    text: `13.3. Во всем остальном, что не урегулировано Договором, Стороны руководствуются действующим законодательством Российской Федерации.`,
                },
            ],
        },

        {
            title: "Приложение №1 к Публичной оферте - Правила возврата денежных средств",
            content: [
                {
                    type: "text",
                    text: `Настоящие Правила возврата денежных средств (далее - Правила) регулируют отношения между Исполнителем и Заказчиком, связанные с возвратом оплаченной стоимости Курса в случае досрочного расторжения Договора.`,
                },
                {
                    type: "text",
                    text: `1. Возврат денежных средств возможен до исполнения сторонами обязательств по Договору, то есть до момента предоставления доступа к Курсу. В момент оплаты Курса и предоставления доступа к Курсу, обязательства сторон по договору считаются исполненными в полном объеме, надлежащим образом.`,
                },
                {
                    type: "text",
                    text: `2. Курсы входят в перечень непродовольственных товаров надлежащего качества, не подлежащих обмену в соответствии с Постановлением Правительства РФ от 31.12.2020 N 2463 "Об утверждении Правил продажи товаров по договору розничной купли-продажи, перечня товаров длительного пользования, на которые не распространяется требование потребителя о безвозмездном предоставлении ему товара, обладающего этими же основными потребительскими свойствами, на период ремонта или замены такого товара, и перечня непродовольственных товаров надлежащего качества, не подлежащих обмену, а также о внесении изменений в некоторые акты Правительства Российской Федерации".`,
                },
                {
                    type: "text",
                    text: `3. В случае отказа Заказчика от Договора и наличия у него права на возврат денежных средств в соответствии с п. 1 Правил, Исполнитель направляет Заказчику соответствующее заявление об отказе от исполнения Договора и возврате денежных средств на адрес на электронный адрес support@hobjob.ru . Исполнитель в течение пяти рабочих дней рассматривает указанное заявление и в случае его правомерности, в течение пяти рабочих дней с даты принятия положительного решения, возвращает уплаченные Исполнителем денежные средства.`,
                },
                {
                    type: "text",
                    text: `4. В случае оплаты в Рассрочку*/Кредит и отказа Заказчика от Договора Исполнитель возвращает Заказчику только свое Вознаграждение и не возмещает проценты\расходы, понесенные Заказчиком при заключении договора с Банком-партнером.*Рассрочка — это форма кредита (займа), при которой переплаты по кредиту (займу) не возникает за счет скидки на товар, предоставляемой продавцом. Процентная ставка по продукту «Рассрочка» - от 6,709% до 70% годовых. Минимальная сумма - 3000 р., максимальная сумма - 500 000 рублей. Срок предоставления - от 3 до 36 месяцев. Ваш тариф и размер ежемесячного платежа будет определен по результатам рассмотрения заявки. Подробнее на сайте [www.tinkoff.ru](http://www.tinkoff.ru/). АО «Тинькофф Банк», ООО «Микрофинансовая компания «Т-Финанс».`,
                },
                {
                    type: "text",
                    text: `Пос-кредит (займ) – это форма кредита (займа), предоставленная непосредственно в точке продаж или на сайте интернет-магазина. Процентная ставка по продукту «Пос-кредит (займ)» - от 6,709% до 70% годовых. Минимальная сумма - 3000 р., максимальная сумма - 500 000 рублей. Срок предоставления - от 3 до 36 месяцев. Ваш ТПВ и размер ежемесячного платежа будет определен по результатам рассмотрения заявки. Подробнее на сайте [www.tinkoff.ru](http://www.tinkoff.ru/). АО «Тинькофф Банк», ООО «Микрофинансовая компания «Т-Финанс».`,
                },
            ],
        },
    ];

    return (
        <>
            <Helmet>
                <title>Публичная оферта - HobJob</title>
            </Helmet>
            <section className="policy">
                <div className="container">
                    <div className="policy-wrapper">
                        <h2 className="title__mb policy__title">
                            Публичная оферта
                        </h2>
                        <div className="policy-block-wrapper">
                            {documentContent.map((item, index) => (
                                <PolicyBlock
                                    {...item}
                                    blockIndex={index}
                                    key={`policy-block-${index}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PublicOffer;
