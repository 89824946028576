import React from 'react'

export interface CoursePageFeedbackModel {
	avatar: string
	name: string
	description: string
}

const CoursePageFeedbackBlock: React.FC<CoursePageFeedbackModel> = ({ avatar, name, description }) => {
	return (
		<div className="course-page-feedback-block">
			{avatar !== "" ? <div className="course-page-feedback-block-avatar" style={{ backgroundImage: `url("${avatar}")` }}></div> : <div className="course-page-feedback-block-avatar"><p>{name.split("")[0]}</p></div>}

			<div className="course-page-feedback-block-text">
				<h4 className="course-page-feedback-block-text__name">
					{name}
				</h4>

				<div className="course-page-feedback-block-text-stars">
					<svg width="73" height="12" viewBox="0 0 73 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.61951 0.345492C5.76919 -0.115164 6.42089 -0.115164 6.57057 0.345491L7.66664 3.71885C7.73358 3.92486 7.92555 4.06434 8.14217 4.06434H11.6891C12.1735 4.06434 12.3749 4.68415 11.983 4.96885L9.11347 7.0537C8.93822 7.18102 8.8649 7.4067 8.93183 7.61271L10.0279 10.9861C10.1776 11.4467 9.65034 11.8298 9.25848 11.5451L6.38893 9.46024C6.21369 9.33291 5.97639 9.33291 5.80115 9.46024L2.9316 11.5451C2.53974 11.8298 2.0125 11.4467 2.16218 10.9861L3.25825 7.61271C3.32519 7.4067 3.25186 7.18102 3.07661 7.0537L0.207066 4.96885C-0.184791 4.68415 0.0165964 4.06434 0.500958 4.06434H4.04791C4.26453 4.06434 4.45651 3.92486 4.52344 3.71885L5.61951 0.345492Z" fill="#F59300" />
						<path d="M20.6195 0.345492C20.7692 -0.115164 21.4209 -0.115164 21.5706 0.345491L22.6666 3.71885C22.7336 3.92486 22.9256 4.06434 23.1422 4.06434H26.6891C27.1735 4.06434 27.3749 4.68415 26.983 4.96885L24.1135 7.0537C23.9382 7.18102 23.8649 7.4067 23.9318 7.61271L25.0279 10.9861C25.1776 11.4467 24.6503 11.8298 24.2585 11.5451L21.3889 9.46024C21.2137 9.33291 20.9764 9.33291 20.8011 9.46024L17.9316 11.5451C17.5397 11.8298 17.0125 11.4467 17.1622 10.9861L18.2582 7.61271C18.3252 7.4067 18.2519 7.18102 18.0766 7.0537L15.2071 4.96885C14.8152 4.68415 15.0166 4.06434 15.501 4.06434H19.0479C19.2645 4.06434 19.4565 3.92486 19.5234 3.71885L20.6195 0.345492Z" fill="#F59300" />
						<path d="M35.6195 0.345492C35.7692 -0.115164 36.4209 -0.115164 36.5706 0.345491L37.6666 3.71885C37.7336 3.92486 37.9256 4.06434 38.1422 4.06434H41.6891C42.1735 4.06434 42.3749 4.68415 41.983 4.96885L39.1135 7.0537C38.9382 7.18102 38.8649 7.4067 38.9318 7.61271L40.0279 10.9861C40.1776 11.4467 39.6503 11.8298 39.2585 11.5451L36.3889 9.46024C36.2137 9.33291 35.9764 9.33291 35.8011 9.46024L32.9316 11.5451C32.5397 11.8298 32.0125 11.4467 32.1622 10.9861L33.2582 7.61271C33.3252 7.4067 33.2519 7.18102 33.0766 7.0537L30.2071 4.96885C29.8152 4.68415 30.0166 4.06434 30.501 4.06434H34.0479C34.2645 4.06434 34.4565 3.92486 34.5234 3.71885L35.6195 0.345492Z" fill="#F59300" />
						<path d="M50.6195 0.345492C50.7692 -0.115164 51.4209 -0.115164 51.5706 0.345491L52.6666 3.71885C52.7336 3.92486 52.9256 4.06434 53.1422 4.06434H56.6891C57.1735 4.06434 57.3749 4.68415 56.983 4.96885L54.1135 7.0537C53.9382 7.18102 53.8649 7.4067 53.9318 7.61271L55.0279 10.9861C55.1776 11.4467 54.6503 11.8298 54.2585 11.5451L51.3889 9.46024C51.2137 9.33291 50.9764 9.33291 50.8011 9.46024L47.9316 11.5451C47.5397 11.8298 47.0125 11.4467 47.1622 10.9861L48.2582 7.61271C48.3252 7.4067 48.2519 7.18102 48.0766 7.0537L45.2071 4.96885C44.8152 4.68415 45.0166 4.06434 45.501 4.06434H49.0479C49.2645 4.06434 49.4565 3.92486 49.5234 3.71885L50.6195 0.345492Z" fill="#F59300" />
						<path d="M65.6195 0.345492C65.7692 -0.115164 66.4209 -0.115164 66.5706 0.345491L67.6666 3.71885C67.7336 3.92486 67.9256 4.06434 68.1422 4.06434H71.6891C72.1735 4.06434 72.3749 4.68415 71.983 4.96885L69.1135 7.0537C68.9382 7.18102 68.8649 7.4067 68.9318 7.61271L70.0279 10.9861C70.1776 11.4467 69.6503 11.8298 69.2585 11.5451L66.3889 9.46024C66.2137 9.33291 65.9764 9.33291 65.8011 9.46024L62.9316 11.5451C62.5397 11.8298 62.0125 11.4467 62.1622 10.9861L63.2582 7.61271C63.3252 7.4067 63.2519 7.18102 63.0766 7.0537L60.2071 4.96885C59.8152 4.68415 60.0166 4.06434 60.501 4.06434H64.0479C64.2645 4.06434 64.4565 3.92486 64.5234 3.71885L65.6195 0.345492Z" fill="#F59300" />
					</svg>
				</div>

				<p className="course-page-feedback-block-text__description">
					{description}
				</p>
			</div>
		</div >
	)
}

export default CoursePageFeedbackBlock