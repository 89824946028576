import React from 'react'
import { Field, reduxForm, InjectedFormProps } from "redux-form";

import { useTypedSelector } from "../../../hooks/useTypedSelector";

import { RenderInput, BtnLoader } from "../../";

import Logo from '../../../assets/images/logo.svg'

import validate from "./validate";

const OrderForm: React.FC<InjectedFormProps<{}>> = ({ handleSubmit }) => {
	const { isSend } = useTypedSelector(({ register }) => register)

	return (
		<form className='order-form' onSubmit={handleSubmit}>
			<img src={Logo} alt="" className="order-form__logo" />

			<h2 className="order-form__title">
				Создайте аккаунт для начала обучения
			</h2>

			<div className="order-form-input">
				<Field
					component={RenderInput}
					type="text"
					name="email"
					label="Электронная почта"
				/>
			</div>

			<div className="order-form-input">
				<Field
					component={RenderInput}
					type="password"
					name="password"
					label="Придумайте пароль"
				/>
			</div>

			{isSend ? (
				<button className="btn order-form__btn disabled" disabled>
					Создать аккаунт и перейти к оплате <span className='loader'></span>
				</button>
			) : (
				<button className="btn order-form__btn">Создать аккаунт и перейти к оплате</button>
			)}

			<span className="order-form__policy">
				Нажимая на кнопку, я соглашаюсь на обработку{" "}
				<a href="/policy">персональных данных</a> и{" "}
				<a href="/public-offer">публичной офертой</a>
			</span>

		</form>
	)
}


export default reduxForm<{}>({
	form: "order-form",
	validate,
})(OrderForm);