import React from "react";

import Love from "../../../../assets/images/love.png";

import { CourseGood } from '../../../../models/Course/ICourseGood'

import SubscribeImage from "../../../../assets/images/subscribe-block-image.jpg";
import SubscribeImageMedia from "../../../../assets/images/subscribe-block-image-media.jpg";

const CoursePagePriceChoiceSubscribe: React.FC<CourseGood> = ({ _id, page }) => {
	let itemsService: string[] | null = page ? JSON.parse(page).buy.service : null

	return (
		<div className="course-page-price-products-block course-page-price-products-block-subscribe">
			<div className="course-page-price-products-block-text">
				<h3 className="course-page-price-products-block-text__title">
					Подписка HobJob
				</h3>

				<p className="course-page-price-products-block-text__subtitle">
					Первые 7 дней за 1₽, далее 499₽ в месяц
				</p>

				<div className="course-page-price-products-block-text-services">
					<p className="course-page-price-products-block-text-services__item">
						<svg
							width="17"
							height="15"
							viewBox="0 0 17 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 8.55319C1.35344 9.05484 1.99095 9.33443 2.51005 9.62884C3.73887 10.3258 4.87341 11.1718 6.02955 11.9811C6.50626 12.3148 7.24248 12.7081 7.56028 13.2281C7.65966 13.3907 7.69731 13.1197 7.76714 12.974C8.02023 12.446 8.16902 11.8806 8.43203 11.3546C9.10787 10.0029 9.9949 8.77056 10.8522 7.53073C12.1788 5.61236 13.4636 3.6302 14.9391 1.82151C15.113 1.60833 15.4142 1.13333 15.6809 1"
								stroke="#DFAB7D"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M1 8.50003C2.41662 9.60185 3.83312 10.6989 5.29669 11.7388C5.74638 12.0583 6.13423 12.464 6.60284 12.7524C6.78275 12.8631 6.96431 12.9421 7.14066 13.0479C7.30999 13.1495 7.63947 13.2222 7.75532 13.3671C7.78374 13.4026 7.92318 12.898 7.93853 12.8351C8.02205 12.4935 8.15014 12.1865 8.25768 11.8541C8.53994 10.9816 9.01757 10.1906 9.40426 9.36292C9.69347 8.7439 10.0651 8.18097 10.4149 7.59578C10.8269 6.90659 11.2086 6.22674 11.6797 5.5745C12.1232 4.96041 12.5754 4.36585 13.0745 3.79554C13.5955 3.20007 14.2342 2.7404 14.8298 2.22344C15.1097 1.9805 15.4824 1.59125 15.8286 1.43739C15.9239 1.39504 15.9667 1.36578 16 1.26599"
								stroke="#DFAB7D"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
						Доступ 24/7 ко всем курсам
					</p>

					<p className="course-page-price-products-block-text-services__item curp">
						<div className="message-wrapper">
							<span>
								Оформляя подписку, вы помогаете детям с заболеваниями, так как с каждой подписки мы отправляем 9₽ в фонд <a href="https://www.dobryaki.ru/wards">«Клуб добряков»</a>. Обучаясь новым навыкам на HobJob, вы помогаете детям <img src={Love} alt="" />
							</span>
						</div>

						<svg
							viewBox="0 0 17 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 8.55319C1.35344 9.05484 1.99095 9.33443 2.51005 9.62884C3.73887 10.3258 4.87341 11.1718 6.02955 11.9811C6.50626 12.3148 7.24248 12.7081 7.56028 13.2281C7.65966 13.3907 7.69731 13.1197 7.76714 12.974C8.02023 12.446 8.16902 11.8806 8.43203 11.3546C9.10787 10.0029 9.9949 8.77056 10.8522 7.53073C12.1788 5.61236 13.4636 3.6302 14.9391 1.82151C15.113 1.60833 15.4142 1.13333 15.6809 1"
								stroke="#dd9e5e"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M1 8.50003C2.41662 9.60185 3.83312 10.6989 5.29669 11.7388C5.74638 12.0583 6.13423 12.464 6.60284 12.7524C6.78275 12.8631 6.96431 12.9421 7.14066 13.0479C7.30999 13.1495 7.63947 13.2222 7.75532 13.3671C7.78374 13.4026 7.92318 12.898 7.93853 12.8351C8.02205 12.4935 8.15014 12.1865 8.25768 11.8541C8.53994 10.9816 9.01757 10.1906 9.40426 9.36292C9.69347 8.7439 10.0651 8.18097 10.4149 7.59578C10.8269 6.90659 11.2086 6.22674 11.6797 5.5745C12.1232 4.96041 12.5754 4.36585 13.0745 3.79554C13.5955 3.20007 14.2342 2.7404 14.8298 2.22344C15.1097 1.9805 15.4824 1.59125 15.8286 1.43739C15.9239 1.39504 15.9667 1.36578 16 1.26599"
								stroke="#dd9e5e"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>

						<p>
							Обучаясь, вы помогаете детям{" "}

							<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.5 10.0478C9.54535 9.15232 9.85019 8.42179 10.4145 7.85622C10.9839 7.28541 11.7775 7 12.7953 7C13.7376 7 14.506 7.26969 15.1006 7.80908C15.7002 8.34847 16 9.02664 16 9.84358C16 10.9904 15.4734 11.8938 14.4203 12.5536C13.9467 12.8469 13.6217 13.1244 13.4453 13.3863C13.274 13.6481 13.1884 13.9885 13.1884 14.4074V14.918H11.8657V14.1954C11.8607 13.6821 11.9791 13.237 12.2209 12.86C12.4678 12.4829 12.8533 12.1321 13.3773 11.8074C13.8207 11.5246 14.1331 11.2418 14.3145 10.959C14.501 10.6762 14.5942 10.328 14.5942 9.91428C14.5942 9.42202 14.4229 9.01878 14.0802 8.70458C13.7426 8.38513 13.2967 8.22541 12.7424 8.22541C12.1731 8.22541 11.7145 8.38775 11.3669 8.71243C11.0242 9.03711 10.8328 9.48224 10.7924 10.0478H9.5ZM12.5308 18.5C12.289 18.5 12.0849 18.4136 11.9186 18.2408C11.7523 18.068 11.6692 17.8559 11.6692 17.6045C11.6692 17.3479 11.7523 17.1358 11.9186 16.9682C12.0849 16.7954 12.289 16.709 12.5308 16.709C12.7777 16.709 12.9818 16.7954 13.143 16.9682C13.3093 17.1358 13.3924 17.3479 13.3924 17.6045C13.3924 17.8559 13.3093 18.068 13.143 18.2408C12.9818 18.4136 12.7777 18.5 12.5308 18.5Z" fill="#DD9E5E" />
								<path d="M24.25 12.5C24.25 18.9893 18.9893 24.25 12.5 24.25C6.01065 24.25 0.75 18.9893 0.75 12.5C0.75 6.01065 6.01065 0.75 12.5 0.75C18.9893 0.75 24.25 6.01065 24.25 12.5Z" stroke="#DD9E5E" strokeWidth="1.5" />
							</svg>
						</p>
					</p>

					<p className="course-page-price-products-block-text-services__item">
						<svg
							width="17"
							height="15"
							viewBox="0 0 17 15"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1 8.55319C1.35344 9.05484 1.99095 9.33443 2.51005 9.62884C3.73887 10.3258 4.87341 11.1718 6.02955 11.9811C6.50626 12.3148 7.24248 12.7081 7.56028 13.2281C7.65966 13.3907 7.69731 13.1197 7.76714 12.974C8.02023 12.446 8.16902 11.8806 8.43203 11.3546C9.10787 10.0029 9.9949 8.77056 10.8522 7.53073C12.1788 5.61236 13.4636 3.6302 14.9391 1.82151C15.113 1.60833 15.4142 1.13333 15.6809 1"
								stroke="#DFAB7D"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M1 8.50003C2.41662 9.60185 3.83312 10.6989 5.29669 11.7388C5.74638 12.0583 6.13423 12.464 6.60284 12.7524C6.78275 12.8631 6.96431 12.9421 7.14066 13.0479C7.30999 13.1495 7.63947 13.2222 7.75532 13.3671C7.78374 13.4026 7.92318 12.898 7.93853 12.8351C8.02205 12.4935 8.15014 12.1865 8.25768 11.8541C8.53994 10.9816 9.01757 10.1906 9.40426 9.36292C9.69347 8.7439 10.0651 8.18097 10.4149 7.59578C10.8269 6.90659 11.2086 6.22674 11.6797 5.5745C12.1232 4.96041 12.5754 4.36585 13.0745 3.79554C13.5955 3.20007 14.2342 2.7404 14.8298 2.22344C15.1097 1.9805 15.4824 1.59125 15.8286 1.43739C15.9239 1.39504 15.9667 1.36578 16 1.26599"
								stroke="#DFAB7D"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
						Можно отменить в любой момент
					</p>

					{itemsService ? (
						itemsService.map((item, index) => (
							<p className="course-page-price-products-block-text-services__item" key={`course-page-price-products-block-text-services__item-${index}`}>
								<svg
									width="17"
									height="15"
									viewBox="0 0 17 15"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 8.55319C1.35344 9.05484 1.99095 9.33443 2.51005 9.62884C3.73887 10.3258 4.87341 11.1718 6.02955 11.9811C6.50626 12.3148 7.24248 12.7081 7.56028 13.2281C7.65966 13.3907 7.69731 13.1197 7.76714 12.974C8.02023 12.446 8.16902 11.8806 8.43203 11.3546C9.10787 10.0029 9.9949 8.77056 10.8522 7.53073C12.1788 5.61236 13.4636 3.6302 14.9391 1.82151C15.113 1.60833 15.4142 1.13333 15.6809 1"
										stroke="#DFAB7D"
										strokeWidth="2"
										strokeLinecap="round"
									/>
									<path
										d="M1 8.50003C2.41662 9.60185 3.83312 10.6989 5.29669 11.7388C5.74638 12.0583 6.13423 12.464 6.60284 12.7524C6.78275 12.8631 6.96431 12.9421 7.14066 13.0479C7.30999 13.1495 7.63947 13.2222 7.75532 13.3671C7.78374 13.4026 7.92318 12.898 7.93853 12.8351C8.02205 12.4935 8.15014 12.1865 8.25768 11.8541C8.53994 10.9816 9.01757 10.1906 9.40426 9.36292C9.69347 8.7439 10.0651 8.18097 10.4149 7.59578C10.8269 6.90659 11.2086 6.22674 11.6797 5.5745C12.1232 4.96041 12.5754 4.36585 13.0745 3.79554C13.5955 3.20007 14.2342 2.7404 14.8298 2.22344C15.1097 1.9805 15.4824 1.59125 15.8286 1.43739C15.9239 1.39504 15.9667 1.36578 16 1.26599"
										stroke="#DFAB7D"
										strokeWidth="2"
										strokeLinecap="round"
									/>
								</svg>

								{item}
							</p>
						))
					) : null}
				</div>

				<div className="course-page-price-products-block-text-btn">
					{/* {userInfo._id === "" ? (
						<button
							className="btn course-page-price-products-block-text-btn__btn"
							onClick={() =>
								dispatch(
									changePriceCurrentSection(
										CoursePagePriceSections.BUY_REGISTER
									) as any
								)
							}
						>
							Попробуйте 7 дней за 1₽
						</button>
					) : (
						<button
							className="btn course-page-price-products-block-text-btn__btn"
							onClick={() =>
								dispatch(sendCreatePaymentCourse(_id) as any)
							}
						>
							Попробуйте 7 дней за 1₽
						</button>
					)} */}

					<a
						href={`/order/${_id}/subscribe`}
						className="btn course-page-price-products-block-text-btn__btn"
					>
						Попробуйте 7 дней за 1₽
					</a>

					{/* <CoursePagePriceChoiceSubscribeTimer /> */}
				</div>
			</div>

			<picture>
				<source
					media="(max-width: 600px)"
					srcSet={SubscribeImageMedia}
				/>

				<img className="course-page-price-products-block-subscribe__image" alt='' src={SubscribeImage} />
			</picture>
		</div>
	);
};

export default CoursePagePriceChoiceSubscribe;
