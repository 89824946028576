import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { sendRegister } from '../redux/actions/register'
import { fetchCourseById } from '../redux/actions/courses'

import { useTypedSelector } from '../hooks/useTypedSelector'

import { OrderForm, OrderPaymentSubscribe, OrderPaymentCourse, OrderProductSubscribe, OrderProductBuy, Loader } from '../components/'

const Order: React.FC = () => {
	const { id, type, paymentNumber } = useParams()
	const dispatch = useDispatch()

	const { courseById, isLoadedCourseById } = useTypedSelector(({ courses }) => courses)

	React.useEffect(() => {
		if (id && !isLoadedCourseById) dispatch(fetchCourseById(id) as any)
	}, [])

	const onSubmit = ({ email, password }: any) => {
		if (type === "subscribe") {
			// try {
			// 	var _tmr = window._tmr || (window._tmr = []);

			// 	_tmr.push({
			// 		type: "reachGoal",
			// 		id: 3311444,
			// 		goal: courseById.adsVk.subscribe,
			// 	});

			// 	var ym = window.ym || (window.ym = []);

			// 	ym(84942475, 'reachGoal', `${courseById.title} (Подписка)`)
			// } catch (e) {
			// 	console.log(e);
			// }

			return dispatch(
				sendRegister(
					{
						email,
						password,
						paymentInfo: `subscribe.test`,
						addSubscribeCourseId: courseById._id,
					},
					"subscribe",
					courseById._id
				) as any
			);
		} else {
			// try {
			// 	var _tmr = window._tmr || (window._tmr = []);

			// 	_tmr.push({
			// 		type: "reachGoal",
			// 		id: 3311444,
			// 		goal: courseById.adsVk.buy,
			// 	});

			// 	var ym = window.ym || (window.ym = []);

			// 	ym(84942475, 'reachGoal', `${courseById.title} (Покупка)`)
			// } catch (e) {
			// 	console.log(e);
			// }

			return dispatch(
				sendRegister(
					{ email, password, paymentInfo: `buy.${courseById._id}.${localStorage.getItem("device")}` },
					"buy",
					courseById._id
				) as any
			);
		}
	}

	return (
		<section className="order">
			<div className="container">
				<div className="order-wrapper">
					{isLoadedCourseById ? (
						<>
							{paymentNumber ? (
								type === "subscribe" ? (
									<OrderPaymentSubscribe courseId={id ? id : ""} paymentNumber={paymentNumber} />
								) : (
									<OrderPaymentCourse paymentNumber={paymentNumber} />
								)
							) : (
								<OrderForm onSubmit={onSubmit} />
							)}

							{type === "subscribe" ? <OrderProductSubscribe course={courseById} /> : <OrderProductBuy course={courseById} />}
						</>
					) : (
						<Loader />
					)}
				</div>
			</div>
		</section>
	)
}

export default Order