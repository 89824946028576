import React from "react";

const Tiktok: React.FC = () => {
    return (
        <svg
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="100" height="100" rx="25" fill="#DD9E5E" />
            <path
                d="M70.1508 32.3665C69.7225 32.1485 69.3055 31.9095 68.9015 31.6504C67.7266 30.8854 66.6494 29.984 65.6931 28.9656C63.3003 26.2692 62.4066 23.5336 62.0774 21.6184H62.0907C61.8157 20.0286 61.9294 19 61.9466 19H51.0481V60.5057C51.0481 61.063 51.0481 61.6138 51.0243 62.158C51.0243 62.2257 51.0177 62.2882 51.0137 62.3611C51.0137 62.3911 51.0137 62.4223 51.0071 62.4536C51.0071 62.4614 51.0071 62.4692 51.0071 62.477C50.8923 63.9662 50.4076 65.4048 49.5957 66.6662C48.7838 67.9277 47.6696 68.9732 46.3511 69.711C44.977 70.481 43.4228 70.8848 41.8418 70.8828C36.7641 70.8828 32.6487 66.8049 32.6487 61.7687C32.6487 56.7325 36.7641 52.6546 41.8418 52.6546C42.803 52.6537 43.7583 52.8027 44.6722 53.096L44.6854 42.1668C41.911 41.8139 39.0925 42.031 36.4076 42.8046C33.7227 43.5782 31.2298 44.8914 29.086 46.6614C27.2075 48.2689 25.6283 50.1869 24.4194 52.3291C23.9593 53.1103 22.2236 56.2494 22.0134 61.3442C21.8812 64.236 22.7629 67.232 23.1833 68.4702V68.4962C23.4477 69.2254 24.4723 71.7135 26.1419 73.8111C27.4883 75.4936 29.079 76.9717 30.8627 78.1976V78.1715L30.8892 78.1976C36.1652 81.7286 42.015 81.4969 42.015 81.4969C43.0276 81.4565 46.4199 81.4969 50.2721 79.6988C54.5448 77.7054 56.9772 74.7355 56.9772 74.7355C58.5312 72.961 59.7669 70.9386 60.6312 68.7553C61.6174 66.2021 61.9466 63.1397 61.9466 61.9158V39.8961C62.0788 39.9742 63.8396 41.1213 63.8396 41.1213C63.8396 41.1213 66.3765 42.7228 70.3345 43.7657C73.1742 44.5078 77 44.6641 77 44.6641V34.0084C75.6595 34.1516 72.9375 33.7349 70.1508 32.3665Z"
                fill="white"
            />
        </svg>
    );
};

export default Tiktok;
